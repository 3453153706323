<template>
	<v-container
		v-if="hasPermission($route.meta.permission)"
		id="regular-tables"
		tag="section"
	>
		<v-card  class="ma-0" flat>
			<!-- filter row  -->
			<v-row class="ma-0">
				<v-col cols="12"><span class="text-h2">Transaction</span></v-col>
			</v-row>
			<v-row class="ma-0 align-end">
				<v-col cols="12" sm="3">
					<v-menu
						ref="delivery_date_menu"
						v-model="dateRangeMenue"
						:close-on-content-click="false"
						:return-value.sync="date"
						transition="scale-transition"
						min-width="auto"
						offset-y
						dense
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="dateRangeText"
								label="Pick a date range"
								range
								prepend-inner-icon="mdi-calendar"
								class="white"
								dense
								:append-icon="dateRangeText && 'mdi-close'"
								@click:append="ClearDateFilter"
								outlined
								hide-details
								v-bind="attrs"
								v-on="on"
							>
								>
							</v-text-field>
						</template>
						<v-date-picker
							class="ma-0"
							color="munjz-accent"
							v-model="dates"
							range
							dense
						>
							<v-spacer></v-spacer>

							<v-btn text color="primary" @click="dateRangeMenue = false">
								OK
							</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="6" sm="3">
					<v-autocomplete
						v-model="selectedSellers"
						:items="sellers"
						chips
						hide-details
						multiple
						outlined
						dense
						item-text="name"
						item-value="id"
						label="filter by seller"
					>
						<template v-slot:selection="data">
							<v-chip
								small
								v-bind="data.attrs"
								:input-value="data.selected"
								close
								@click="data.select"
								@click:close="removeFromSelcted(data.item, selectedSellers)"
							>
								{{ data.item.name }}
							</v-chip>
						</template>
					</v-autocomplete>
				</v-col>

				<v-col cols="6" sm="4" md="3" class="me-auto">
					<v-autocomplete
						v-model="selectedtransactionStates"
						:items="transactionStateOptions"
						chips
						multiple
						dense
						class="white"
						outlined
						hide-details
						item-text="statusText"
						item-value="id"
						label="All Status"
					>
						<template v-slot:selection="data">
							<v-chip
								v-bind="data.attrs"
								:input-value="data.selected"
								close
								small
								outlined
								:color="data.item.color"
								dense
								@click="data.select"
								@click:close="
									removeFromSelcted(data.item, selectedtransactionStates)
								"
							>
								{{ data.item.statusText }}
							</v-chip>
						</template>
					</v-autocomplete>
				</v-col>
				<v-col cols="12" sm="3" class="d-flex justify-center justify-sm-start">
					<v-card min-width="230" class="pa-3 ma-0 rounded-lg"
						><v-card-title style="color:orange" class="pa-0 text-h3"
							>Total Amount</v-card-title
						>
						<v-card-title class="pa-0 text-h3 font-weight-bold">
							{{ formatTocurrency(totalAmount) }}
							SAR</v-card-title
						>
					</v-card>
				</v-col>
			</v-row>

			<v-data-table
				dense
				:headers="headers"
				:items="transactions"
				:server-items-length="totaltransactions"
				:loading="loading"
				loading-text="Loading... Please wait"
				no-data-text="No transactions have been made yet"
				:options.sync="options"
				:footer-props="{
					itemsPerPageOptions: [20, 40, 60, 80, 100],
					prevIcon: 'mdi-arrow-collapse-left',
					nextIcon: 'mdi-arrow-collapse-right'
				}"
				class="mt-8 px-8 mt-4"
			>
				<template v-slot:item.order_no="{ item }">
					<a @click="viewOrder(item)" style="color: #1976d2"
						>{{ item.order_no }}
					</a>
				</template>

				<template v-slot:item.city="{ item }">
					<span>{{
						item.location ? item.location : item.city ? item.city.name_en : ""
					}}</span>
				</template>

				<template v-slot:item.status="{ item }">
					<v-chip
						small
						outlined
						class="ma-2"
						:color="transactionStateOptions[item.status].color"
						>{{ transactionStateOptions[item.status].statusText }}</v-chip
					>

					<v-chip
						small
						v-if="item.approval_sent == 1"
						class="ma-2"
						style="background: green; color: #fff"
						color="#E9C510"
						>approval sent</v-chip
					>
				</template>

				<template v-slot:item.sp="{ item }">
					<a
						v-if="item.sp"
						style="color: #1976d2"
						@click="opensp(String(item.sp.id))"
						>{{ item.sp.name }}</a
					>
					<span v-else>N/A</span>
				</template>

				<template v-slot:item.area="{ item }">
					<span v-if="item.area">{{ item.area.name }}</span>
					<span v-else>Not confirmed</span>
				</template>

				<template v-slot:item.start_time="{ item }">
					<span v-if="item.start_time">{{ item.start_time }}</span>
					<span v-else>Not confirmed</span>
				</template>
				<template width="0" v-slot:item.customer_name="{ item }">
					<a style="color: #1976d2">
						{{ item.customer_name }}
						<br />
						<span> ({{ item.customer_phone }}) </span>
					</a>
				</template>

				<template width="0" v-slot:item.details="{ item }">
					{{ item.details[0] ? item.details[0].service.name : "" }}
					-
					{{ item.details[0] ? item.details[0].subservice.name : "" }}
				</template>

				<template v-slot:item.date="{ item }">
					<p>{{ item.status == 1 ? "High" : "Normal" }}</p>
				</template>

				<template v-slot:item.users_status="{ item }">
					<v-chip
						style="color: #fff"
						v-if="item.users_status == 0"
						class="ma-2"
						color="red"
						>Inactive</v-chip
					>
					<v-chip
						style="color: #fff"
						v-if="item.users_status == 1"
						class="ma-2"
						color="green"
						>Active</v-chip
					>
				</template>

				<template v-slot:item.user_id_from="{ item }">
					<v-icon color="red" @click="remove(item.id)">close</v-icon>
				</template>
			</v-data-table>
		</v-card>
	</v-container>
	<v-container fluid v-else class="pa-10 " id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat>
					<!-- <v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					> -->
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
/* eslint-disable */
import moment from "moment";

export default {
	data() {
		return {
			totalAmount: null,
			user_id: localStorage.getItem("userid"),
			role: localStorage.getItem("role"),
			sellers: [],
			selectedSellers: [],
			moment: moment,
			token: localStorage.getItem("token"),
			parent_id: localStorage.getItem("parent_id"),
			deliveryDateFilter: "",
			deliveryDateFilterFromDialog: "",
			deliveryDateFilterToDialog: "",
			deliveryDateFilterTo: "",
			deliveryDateFilterFrom: "",
			totaltransactions: 0,
			transactions: [],
			options: {},
			dates: [],
			date: null,
			transactionsStatusList: [],
			selectedtransactionStates: [],
			dateRangeMenue: false,
			loading: true,
			globalSearch: "",
			headers: [
				{ text: "Date", align: "center", width: 30, value: "delivery_date" },
				{
					text: "Order no",
					align: "center",
					width: 30,
					value: "order_no"
				},
				{
					text: "Status",
					align: "center",
					width: 30,
					value: "status"
				},
				{
					text: "Order Amount",
					align: "center",
					width: 30,
					value: "final_total_price_with_vat"
				},

				{
					text: "Branch",
					align: "center",
					width: 30,
					value: "branch.name"
				},
				{
					text: "Seller",
					align: "center",
					width: 30,
					value: "order_seller.name"
				}
				// {
				//   text: "Services",
				//   align: "center",
				//   width: 30,
				//   value: "details",
				// },
			],
			nameRules: [v => !!v || "Field is required"],
			transactionStateOptions: [
				{ id: 0, statusText: "Pending", color: "#aeb400" },
				{
					id: 1,
					statusText: "Requested",
					color: "primary"
				},
				{
					id: 2,
					statusText: "Confirmed",
					color: "teal"
				},
				{ id: 3, statusText: "On the Way", color: "purple" },
				{
					id: 4,
					statusText: "In Progress",
					color: "orange"
				},
				{
					id: 5,
					statusText: "Completed",
					color: "green"
				}
				// {
				// 	id: 6,
				// 	statusText: "Canceled",
				// 	color: "red"
				// }
			]
		};
	},
	components: {},
	computed: {
		dateRangeText() {
			return this.dates.join(" to ");
		}
	},

	watch: {
		options: {
			handler() {
				this.gettransactions();
			},
			deep: true
		},
		dates: {
			handler() {
				this.gettransactions();
			},
			deep: true
		},
		globalSearch: {
			handler() {
				this.gettransactions();
			},
			deep: true
		},
		selectedtransactionStates: {
			handler() {
				this.gettransactions();
			},
			deep: true
		},
		selectedSellers: {
			handler() {
				this.gettransactions();
			},
			deep: true
		},
		deliveryDateFilterTo: {
			handler() {
				this.gettransactions();
			},
			deep: true
		},
		deliveryDateFilterFrom: {
			handler() {
				this.gettransactions();
			},
			deep: true
		}
	},
	mounted() {
		this.getAdminPermissions();
		this.getAdmins();
	},
	methods: {
		formatTocurrency(val) {
			if (val != null)
				return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
			return "0";
		},
		ClearDateFilter() {
			this.dateRangeMenue = false;
			this.dates = [];
		},
		viewOrder(item) {
			this.$router.push({
				path: `/order-details/${item.id}`
			});
		},
		gettransactions() {
			const { sortBy, sortDesc, page, itemsPerPage } = this.options;
			let payload = {};
			payload = {
				spa: 1,
				user_id: this.parent_id == 0 ? this.user_id : this.parent_id,
				page: page,
				globalsearch: this.globalSearch.replace(/\s+/g, ""),
				per_page: itemsPerPage,
				status: this.statuscode,
				status_ids: this.selectedtransactionStates,
				sellers_ids: this.selectedSellers,
				from: this.dates[0],
				to: this.dates[1]
			};

			if (sortBy.length === 1 && sortDesc.length === 1) {
				if (sortDesc[0]) {
					const direction = "desc";
					payload.direction = direction;
					payload.sortBy = sortBy[0];
				} else {
					const direction = "asc";
					payload.direction = direction;
					payload.sortBy = sortBy[0];
				}
			}

			axios
				.get(`/api/v3/b2b/partner-transaction-orders`, {
					params: payload
				})
				.then(res => {
					this.transactions = res.data.data;
					console.log('res.data :>> ', res.data.data);
					const reducer = (previousValue, currentValue) =>
						previousValue + currentValue;

					this.totaltransactions = res.data.total;
					this.totalAmount = res.data.sum_final_total_price_with_vat;
				})
				.catch(err => console.log(err.response.data))
				.finally(() => (this.loading = false));

			// get by search
		},

		getAdmins() {
			//  alert("user"+this.user_id)
			axios
				.get("/api/v3/b2b/partner-admins", {
					headers: { "X-Authorization": this.token }
				})
				.then(res => {
					this.sellers = res.data.partner_admins;
				})
				.catch(err => console.log(err.response.data));
		},
		removeFromSelcted(item, array) {
			const index = array.indexOf(item.id && item.id);
			if (index >= 0) array.splice(index, 1);
		}
	}
};
</script>
<style scoped>
/* .v-data-table table th {
  font-size: 16px !important;
  font-weight: bolder !important;
  color: #fff !important;
}

.v-textarea textarea {
  font-size: 0.9em !important;
  max-height: 100px !important;
}

label {
  font-size: 12px !important;
  font-weight: bold;
  color: grey;
}
.v-text-field >>> input {
  font-size: 0.9em;
 
} */
</style>
